<template>
  <div id="subpage-content">
    <ProjectsDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <v-col v-for="item in items" :key="item.id" :cols="item.id">
          <v-card id="inf-productsnew-card">
            <div id="inf-productsnew-card-container">
              <img :src="getImageUrl(item.image)" href="" id="inf-productsnew-card-pic" />
              <div id="inf-productsnew-card-content">
                <div id="inf-productsnew-cardtitle" v-html="getItemName(item)"></div>
                <div v-if="item.date != null" id="inf-productsnew-carddate">{{ getItemDate(item) }}</div>
                <div id="pad" v-html="getItemShorttext(item)"></div>
                <v-btn
                  small
                  style="min-width: 100px"
                  id="inf-btn"
                  v-on:click="saveCurrentItem(item)"
                  @click="$router.push('/projects/anyproducts')"
                  >{{ $t("message.More") }}
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-card>
    </div>
  </div>
</template>

<div id="pad" v-html="$t('message.IntGeoloc')"></div>

<script>
import ProjectsDrawer from "../../../views/Navigations/Projects/ProjectsDrawer.vue";
export default {
  components: {
    ProjectsDrawer,
  },
  data: () => ({
    items: [],
  }),

  async mounted() {
    await this.getProductsblock();
  },
  methods: {
    async getProductsblock() {
      await this.$store.dispatch("getAllProductsblocks");
      this.items = await this.$store.getters.getProductsblocks;
      this.items = this.items.sort((b, a) => new Date(a.date) - new Date(b.date));
      console.log(this.items);
    },
    getImageUrl(item) {
      return "data:image/jpeg;base64," + item;
    },
    getItemName(item) {
      if (this.$i18n.locale === "ru") {
        return item.name1;
      } else if (this.$i18n.locale === "gb") {
        return item.name2;
      }
    },
    getItemDate(item) {
      return item.date.slice(0, 10);
    },
    getItemShorttext(item) {
      if (this.$i18n.locale === "ru") {
        return item.shorttext1;
      } else if (this.$i18n.locale === "gb") {
        return item.shorttext2;
      }
    },
    getItemLongtext(item) {
      if (this.$i18n.locale === "ru") {
        return item.shorttext1;
      } else if (this.$i18n.locale === "gb") {
        return item.shorttext2;
      }
    },
    saveCurrentItem(item) {
      localStorage.itemid = item.id;
      localStorage.itemimage = item.image;
      localStorage.itemname1 = item.name1;
      localStorage.itemname2 = item.name2;
      localStorage.itemname3 = item.name3;
      localStorage.itemname4 = item.name4;
      localStorage.itemname5 = item.name5;
      if (item.date != null) localStorage.itemdate = item.date.slice(0, 10);
      localStorage.itemlongtext1 = item.longtext1;
      localStorage.itemlongtext2 = item.longtext2;
      localStorage.itemlongtext3 = item.longtext3;
      localStorage.itemlongtext4 = item.longtext4;
      localStorage.itemlongtext5 = item.longtext5;
      console.log(localStorage.itemname1);
      return null;
    },
  },
  watch: {
    itemdata: function (newValue) {
      if (newValue) {
        localStorage.item = newValue;
      }
    },
  },
};
</script>

<style>
#inf-productsnew-card {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  height: 250px;
  width: 700px;
}
#inf-productsnew-card-container {
  display: flex;
  justify-content: space-around;
}
#inf-btn {
  margin: 10px;
  display: flex;
  align-self: center;
  width: 25%;
  font: 14px/20px Ubuntu, Arial, sans-serif;
  color: rgb(247, 247, 247);
  background-color: #00569c;
  letter-spacing: 0.01em;
}
#inf-productsnew-card-content {
  margin-left: 5%;
}
#inf-productsnew-card-pic {
  max-height: 150px;
  max-width: 230px;
}
#inf-productsnew-carddate {
  font: 11px/20px Ubuntu, Arial, sans-serif;
  border-left: 1px solid #c4c4c4;
  color: #747474;
  padding-left: 8px;
}
#inf-productsnew-cardtitle {
  padding-top: 4px;
  color: #00569c;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}

@media (max-width: 1500px) {
  #content-wrapper {
    flex-direction: column;
  }
}
@media (max-width: 1330px) {
  #content-wrapper {
    flex-direction: column;
  }
  #subpage-content {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 180px;
  }
}
@media (max-width: 1100px) {
  #navcard {
    display: none;
  }
  #inf-productsnew-card-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  #inf-productsnew-card {
    height: 400px;
  }
  #inf-productsnew-card-pic {
    display: flex;
  }
}
@media (max-width: 860px) {
  #inf-productsnew-card {
    height: 450px;
    width: 400px;
  }
  #inf-btn {
    width: 30%;
  }
  #rcol-incard {
    padding: 0px;
  }
}
@media (max-width: 480px) {
  #inf-productsnew-card {
    height: 550px;
    width: 300px;
  }
  #inf-btn {
    width: 50%;
  }
  #subpage-content {
    padding-left: 1%;
    padding-right: 1%;
  }
}
</style>
